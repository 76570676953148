import React from 'react';
import { Box, Text, Image, Flex, Avatar, Heading, IconButton, VStack } from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight,FaQuoteLeft } from 'react-icons/fa';
import testimonials from "../content/testimonialsData"
import QuotesImage from "../../assets/images/QuotesImage.svg";

const TestimonialCard = ({ testimonial }) => {
  return (
    <Box maxW="lg" mx="auto" p={6} borderRadius="md" textAlign="center"position="relative">
      <IconButton
        icon={<FaQuoteLeft />}
        alignSelf="center"
        aria-label="Quote Icon"
        bg="white"
        color="brand.500"


      />


      <Text fontSize="lg" color="#6C727E">
        {testimonial.feedback}
      </Text>
      <Flex mt={10} align="center" justify="center">
        <Avatar src={testimonial.avatar} size="lg" name={testimonial.name} />
        <Box ml={3}>
          <Text fontWeight="bold" color="#6D758F"  >{testimonial.name}</Text>
          <Text fontSize="lg" color="#B4B9C9">
            {testimonial.title}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};


const TestimonialSlider = () => {
  const [currentTestimonial, setCurrentTestimonial] = React.useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) =>
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const arrowStyles = {
     width: "40px", height: "40px",
    cursor: 'pointer', color: '#6D758F', backgroundColor: '#F2ECFB', borderWidth: '1px', borderColor: "#E5E7EB"
  };


  return (
    <Box width="full"  align="center" backgroundImage={QuotesImage} bgSize="contain" bgPos={"right"} bgRepeat={"no-repeat"}>
    <Box maxWidth={{base:"100%", md: "70%"}}  >
        <VStack align="center" gap="32px" m="20">  
            <Heading as="h2" fontSize="5xl" fontWeight="semibold" color="#422678" textAlign={"center"} >
                What our clients have to say
            </Heading>
            <Text fontSize="lg" color="#4D5761" textAlign={"center"}>
                From startups to industry leaders, our solutions drive success. Join us today and unlock possibilities.
            </Text>
          </VStack >
      
 
        <Flex mt={4} align="center" height= "300px">
            <IconButton icon={<FaArrowLeft /> } style={arrowStyles} onClick={prevTestimonial} aria-label="Previous Testimonial" alignSelf="center"/>
            <TestimonialCard testimonial={testimonials[currentTestimonial]} /> 
            <IconButton icon={<FaArrowRight />} style={arrowStyles}  onClick={nextTestimonial} aria-label="Next Testimonial" alignSelf="center"/>
        </Flex>
    </Box>
    </Box>
  );
};

export default TestimonialSlider;
