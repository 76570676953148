import React from 'react';
import { VStack, Spacer, Text, Image, Button, Flex } from '@chakra-ui/react';

const FeatureCard = ({ buttonText, buttonIcon, title, subtitle, imageSrc, imageAlt, order }) => {
  return (
    <Flex 
    direction={{ base: 'column', md: order === 'reverse' ? 'row-reverse' : 'row' }}  
    p={{ base: 8, md: 10, lg:20 }}
    id="feature-card"
    w="100%"
    >
      <VStack  align={"flex-start"} w={{ base:"100%", md: "50%" }}  p={5}>
        <Button p="2" size="sm" color='white' backgroundColor='#A582E7' leftIcon={buttonIcon}>
          {buttonText}
        </Button>
        <Text fontSize="4xl" fontWeight="600" color="white" textAlign={"left"}>
          {title}
        </Text>
        <Text fontSize="md" textAlign="left" color="white">
          {subtitle}
        </Text>
      </VStack>
      <Spacer/>
      <Image src={imageSrc} alt={imageAlt} objectFit="contain" w={{ base:"100%", md: "50%" }}   p={5}  />
    </Flex>
  );
};

export default FeatureCard;