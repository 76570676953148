import React from 'react';
import { VStack, Flex, Text, Box, Spacer } from '@chakra-ui/react';

const FeaturesList = ({ features }) => (
  <VStack spacing={3} mb={6} align="left" width="full">
    {features.map((feature, index) => (
      <Flex key={index} justify="space-between">
        <Text color="#4D5761">{feature}</Text>
        <Spacer />
        <Box as="span" color="#6B46C1" mr="5" align="right">
          &#10003;
        </Box>
      </Flex>
    ))}
  </VStack>
);

export default FeaturesList;