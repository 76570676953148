import React from 'react';
import { VStack, Text, Flex, Box, Stack, Image } from '@chakra-ui/react';
import IntegrationsIllustration from "../../assets/images/IntegrationsIllustration.png";

const SocialButtons = () => {
  const title = "Effortlessly blend essential assets through integrations";
  const subtitle = "Integrate your different apps and synchronize all your valuable resources, such as email lists and product lists, to craft lucrative advertisements.";

  return (

      <Stack 
        alignItems="center"
        justifyContent="center"
        direction={{ base: 'column', md: 'row' }}
        background="white"
        borderRadius="32px"
        boxShadow="0px 2px 5px rgba(50, 29, 92, 0.16), 0px 10px 24px rgba(50, 29, 92, 0.2)"
        overflow="hidden"
        m={{ base: 5, md: 10, lg: 10  }}
      >
        <VStack 
           m={{ base: 5, md: 10 }} p="10" maxW={{ base: "100%", md: "50%" }} gap="32px">
          <Text
            fontSize="3rem"
            fontWeight="600"
            lineHeight="1.2"
            textAlign="left"
            bgGradient="linear(to-tr, #38245F, #7845DB)"
            bgClip="text"
          >
            {title}
          </Text>
          <Text fontSize="1rem" color="#36345C" textAlign="left">
            {subtitle}
          </Text>
        </VStack>
        <Box minW={{ base: "100%", md: "35%" }} >
          <Image
            src={IntegrationsIllustration}
            alt="Integrations Illustration"
            objectFit="contain"
            w="100%"
          />
        </Box>
      </Stack>
  
  );
};

export default SocialButtons;