import React from 'react';
import { VStack, Box, Text, Image, Flex, Button } from '@chakra-ui/react';
import { FiFeather } from 'react-icons/fi';
import AgencyBrandingIllustration from "../../assets/images/AgencyBrandingIllustration.svg";

export default function Hero6() {
  return (
    <Flex 
    alignItems="flex-start" 
    direction={{ base: 'column', md: 'row' }} 
    gap="20"
    >

      <VStack align="flex-start"  m={{ base:5, md:20 }}spacing={{base: 2, md: 5}} p='2'>
        <Button size={{base: "xs", md: "sm"}} color="white" backgroundColor="#A582E7" leftIcon={<FiFeather />}>
          Agency Branding
        </Button>
        <Text fontSize='5xl' fontWeight="bold" lineHeight="1.2" color="#321D5C" textAlign="left">
          Make it yours
        </Text>
        <Text fontSize='lg' textAlign="left" color="#321D5C">
          Brand the Client Portal links with your own logo, name, colors and favicon.
        </Text>
      </VStack>


    <Box position="relative" maxW={{ base: '100%', md: '70%' }} overflow="hidden" >
      <Image src={AgencyBrandingIllustration}  alt="Agency Branding Illustration" objectFit="cover" maxW="100%"/>
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="50%"
        bg="linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"/>
    </Box>
    </Flex>
  );
}