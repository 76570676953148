import React from 'react';
import { Flex,  VStack, Text , Spacer} from '@chakra-ui/react';
import AiContentGenerator from "../../assets/images/AiContentGenerator.svg";
import MediaPlan from "../../assets/images/MediaPlansIllustration.svg";
import ApprovalProcess from "../../assets/images/ApprovalProcessIllustration.svg";
import { FiCpu, FiClipboard } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import FeatureCard from '../elements/FeatureCard';

export default function Hero3() {
  return (

    <VStack
        m={{ base: 5, md: 20 }}
        background="linear-gradient(180deg, rgb(147, 106, 226) 0%, rgb(120, 69, 219) 100%)"
        borderRadius="32px"
        boxShadow="0px 2px 5px rgba(50, 29, 92, 0.16), 0px 10px 24px rgba(50, 29, 92, 0.2)"       
    >
       
    <VStack 
        align="center" 
        m={{base:5, md:10}} id="features" w="100%">
  
        <Flex  direction={{ base: 'column', md: 'row' }}  p={{ base: 8, md: 10, lg: 20 }} id="top-feature">
          <VStack  align={"flex-start"} w={{ base:"100%", md: "50%" }}  p={5}>
            <Text fontSize="5xl" fontWeight="600" color="white" textAlign={"left"}>
              Features that you need.
            </Text>
          </VStack>
          <Spacer/>
          <VStack  align={"flex-start"} w={{ base:"100%", md: "50%" }}  p={5}>
          
          <Text fontSize="lg" color="white">
              Use features that normally take days to code, in minutes. Integration with your CRM in 2 minutes. Set auto-responses in 2 minutes. Field validations in 2 minutes. You get the idea.
            </Text>
          </VStack>
        </Flex>

        <FeatureCard
          buttonText="AI Content Generator"
          buttonIcon={<FiCpu />}
          title="AI-Boosted Ad Headlines and Descriptions"
          subtitle="AI Copy Generator to make your headlines and descriptions faster, better and attractive with OpenAI integration on all formats of ad channels."
          imageSrc={AiContentGenerator}
          imageAlt="AI Content Generator"
          order="normal"
        />

        <FeatureCard
          buttonText="Media Plans"
          buttonIcon={<FiClipboard />}
          title="Say Goodbye to Excel: Effortless Media Plan Creation"
          subtitle="No More Excel Sheets! Hassle free media plan creation and instantly share it with your clients for approvals and comments."
          imageSrc={MediaPlan}
          imageAlt="Media Plan"
          order="reverse"
        />

        <FeatureCard
          buttonText="Approval Process"
          buttonIcon={<IoMdCheckmarkCircleOutline />}
          title="Streamlined Client feedback and approval"
          subtitle="Seamless approval process with clients as they can view, comment and collaborate. No more loss of communication and endless emails."
          imageSrc={ApprovalProcess}
          imageAlt="Approval Process"
          order="normal"
        />
      </VStack>
       </VStack>
 
  );
}