import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button, Spacer } from "@chakra-ui/react";
import Logo from "../ui/Logo";
import { Link as ScrollLink } from "react-scroll";

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      {to.startsWith("/") ? (
        <Link to={to}>{children}</Link>
      ) : (
        <ScrollLink to={to.substring(1)} smooth={true} duration={500}>
          {children}
        </ScrollLink>
      )}
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="brand.500"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="brand.500"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="full"
      mb={8}
      p={8}
      bg={["white"]}
      color={["header_menu"]}
      {...props}
    >
      <Flex align="center" bg="white">
        <Logo w="200px" />
      </Flex>

      <Spacer />
      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "flex" }}
        flexBasis={{ base: "100%", md: "auto" }}
        flex="1"
      >
        <Flex
          align="center"
          justify="space-between"
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
          w="100%"
        >
          <MenuItem to="/solutions">Solutions</MenuItem>
          <MenuItem to="/products">Products </MenuItem>
          <MenuItem to="#pricing-section">Pricing </MenuItem>
          <MenuItem to="/resources">Resources </MenuItem>
          <Spacer display={{ base: show ? "block" : "none", md: "flex" }} />
          <MenuItem to="#pricing-section" color="brand.500">
            Get in touch
          </MenuItem>
          <MenuItem to="#pricing-section" isLast>
            <Button
              size="sm"
              rounded="md"
              color={"white"}
              bg={"brand.500"}
              _hover={{
                bg: ["brand.600"],
              }}
            >
              Login
            </Button>
          </MenuItem>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;