import React from 'react';

import Hero1 from "./Hero1";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";
import SocialButtons from "./SocialButtons";
import Hero5 from "./Hero5";
import Hero6 from "./Hero6";
import ThreeTierPricing from './ThreeTierPricing';
import TestimonialsSlider from './TestimonialsSlider';
import LandingLayout from '../layouts/LandingLayout';
import LandingBg from '../../assets/images/backgrounds/Landing page 1443.svg';
import { VStack } from '@chakra-ui/react';

const Hero = () => {
  return (
    <LandingLayout>
      <VStack spacing="48px" m={{base:0, md:10, lg:20}}  w='full' maxWidth="100%"  overflow="hidden" bgImage={LandingBg} bgSize={"cover"} bgRepeat={"no-repeat"}>
        <Hero1/>
        <Hero2/>
        <Hero3/>
        <SocialButtons/>
        <Hero5 />
        <Hero6 />
      <ThreeTierPricing />
      <TestimonialsSlider/>  
     </VStack>
    </LandingLayout>
  );
}

export default Hero;