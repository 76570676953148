import React from "react";
import { Heading, Text, Image, VStack } from "@chakra-ui/react";
import ClientPortal from "../../assets/images/ClientPortalIllustration.svg";

export default function Hero5() {
  return (

    <VStack m={{ base:5, md:20 }} spacing="20" >
      <VStack maxW={{ base:"100%" , md:"70%" }} spacing="5">
        <Heading as="h2" fontSize='5xl' color="#000000"  opacity="0.87" textAlign="center">
           Client Portal
        </Heading>

        <Text fontSize="lg" color="#000000" opacity="0.6" textAlign="center">
            Quickly share media plan, previews on public link or private links for approval, with all campaign views. With option to select which campaign to show to your clients.
        </Text>
      </VStack>
      <VStack minW={{ base:"100%" , md:"95%" }} >
         <Image src={ClientPortal } alt="Client Portal "  objectFit="contain" />
     </VStack>
    </VStack>



 
  
  );
}
