// src/data/pricingData.js

const pricingPlans = [
  {
    planName: "Starter",
    pricePerMonth: "29.99",
    priceAnnualPerMonth: "19.99",
    features: ["All features included", "2 User seats", "2 Workspaces", "500 MB memory"],
  },
  {
    planName: "Growth",
    pricePerMonth: "59.99",
    priceAnnualPerMonth: "49.99",
    features: ["All features included", "5 User seats", "5 Workspaces", "1 GB memory"],
  },
  {
    planName: "Enterprise",
    pricePerMonth: "Custom pricing",
    priceAnnualPerMonth: "Custom pricing",
    features: ["All features included", "Custom User seats", "Custom Workspaces", "1 GB memory", "Dedicated support"],
  },
];

export default pricingPlans;