import React from 'react';
import { Button } from '@chakra-ui/react';

const GetStartedWithPlanButton = ({ planName, isMonthly, ...props }) => {
  const handleClick = () => {
    const queryParams = new URLSearchParams({
      plan: planName.toLowerCase(),
      monthly: isMonthly ? 'true' : 'false',
    }).toString();

    window.location.href = `https://app.whatcampaign.com/register?${queryParams}`;
  };

  return (
    <Button onClick={handleClick} {...props}>
      Get Started
    </Button>
  );
};

export default GetStartedWithPlanButton;