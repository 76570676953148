import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LogoHorizontal from "../../assets/images/logo_horizontal.svg";

export default function Logo(props) {
  return (
    <Box {...props}>
      <Link to="/">
        <Image src={LogoHorizontal} alt="Logo" />
      </Link>
    </Box>
  );
}