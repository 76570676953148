import React from 'react';
import { Box, Text, Divider, VStack } from '@chakra-ui/react';
import FreeTrialButton from '../elements/FreeTrialButton';
import FeaturesList from '../elements/FeaturesList';
import GetStartedWithPlanButton from '../elements/GetStartedWithPlanButton';

const PriceWrapper = ({ planName, pricePerMonth, priceAnnualPerMonth, features, isMonthly }) => {
  const price = isMonthly ? pricePerMonth : priceAnnualPerMonth;
  const isPriceNumber = !isNaN(price);

  return (
    <VStack
      maxW={{ base: 'full', md: '30%' }}
      minW={{ base: 'full', md: '30%' }}
      borderWidth="1px"
      borderRadius="lg"
      bg="#FFFFFF"
      boxShadow="lg"
      p={6}
      m="2"
      align="start"
      minH="100%"
      spacing="10px"
    >
      <Text fontWeight="bold" fontSize="xl" color="#4D5761">
        {planName}
      </Text>
      <Text fontSize="3xl" fontWeight="bold" color="#4D5761">
        {isPriceNumber ? '$' : ''}
        {price}
        <Text as="span" fontSize="sm" fontWeight="normal" color="#9DA4AE">
          {isPriceNumber ? '/month' : ''}
        </Text>
      </Text>
      <Box pt={4} width="full" align="left" h="100%">
        <FreeTrialButton isVisible={isPriceNumber} />
      </Box>
      <Divider />
      <Box pt={4} width="full">
        <FeaturesList features={features} />
        <GetStartedWithPlanButton planName={planName} isMonthly={isMonthly} mt={4} />
      </Box>
    </VStack>
  );
};

export default PriceWrapper;