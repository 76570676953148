

import React, { useState } from 'react';
import { Box, VStack, HStack, Button, Image, Heading,  Text } from '@chakra-ui/react';

import MobileAdPreviewGoogle from "../../assets/images/GoogleAds.svg";
import MobileAdPreviewMeta from "../../assets/images/MetaAds.svg";
import MobileAdPreviewLinkedIn from "../../assets/images/LinkedinAds.svg";
import MobileAdPreviewSnapchat from "../../assets/images/SnapchatAds.svg";
import MobileAdPreviewTikTok from "../../assets/images/TikTokAds.svg";
import GoogleLogo from "../../assets/images/icons/GoogleLogo.svg";
import MetaLogo from "../../assets/images/icons/MetaLogo.svg";
import LinkedInLogo from "../../assets/images/icons/LinkedInLogo.svg";
import SnapchatLogo from "../../assets/images/icons/SnapchatLogo.svg";
import TikTokLogo from "../../assets/images/icons/TikTokLogo.svg";

export default function Hero2() {
  const [currentImage, setCurrentImage] = useState(MobileAdPreviewGoogle);

  const buttons = [
    { name: 'Google', icon: GoogleLogo, image: MobileAdPreviewGoogle },
    { name: 'Meta', icon: MetaLogo, image: MobileAdPreviewMeta},
    { name: 'LinkedIn', icon:LinkedInLogo, image: MobileAdPreviewLinkedIn },
    { name: 'TikTok', icon: TikTokLogo, image: MobileAdPreviewTikTok },
    { name: 'Snapchat', icon: SnapchatLogo, image: MobileAdPreviewSnapchat },
  ];
  return (

    <VStack m={{ base:5, md:20 }} spacing="20" >
      <VStack maxW={{ base:"100%" , md:"80%" }} spacing="5">
        <Heading as='h2' fontSize='5xl' bgGradient='linear(to-tr, #38245F,  #7845DB)' lineHeight="1.2" bgClip='text'  textAlign='center'>
          Create and preview campaigns effortlessly
        </Heading>
        <Text fontSize="lg" color="#4D5761" textAlign="center" >
          Creating previews has never been this quicker. Visualize how your campaigns will look on multiple advertising platforms with the latest ad formats.
        </Text>
      </VStack>
      

        <HStack  maxW={{ base:"100%" , md:"70%" }}  spacing={4} align="center"  >
          <VStack spacing={4} justify="left">
            {buttons.map((button, index) => (
              <Button
                color={currentImage === button.image ? 'white' : 'brand.500'}
                backgroundColor={currentImage === button.image ? 'brand.500' : 'transparent'}
                key={index}
                leftIcon={<Image src={button.icon} alt={`${button.name} Icon`} boxSize="20px" />}
                onClick={() => setCurrentImage(button.image)}
              >
              <Text display={{ base: 'none', md: 'inline' }}>
                  {button.name}
                </Text>
              </Button>
            ))}
          </VStack>

           {currentImage && (
        
        <Box
          justifyContent="center"
          alignItems="center"
          width={{ base: "300px", md: "450px", lg: "500px" }}
          height={{ base: "300px", md: "450px", lg: "500px" }}
          display="flex"
          overflow="hidden"
          bgSize="contain"
          bgRepeat="no-repeat"
          bgPos="center"
          bgImage={currentImage}
        >
          </Box>
        
          )}
        
        </HStack>

    </VStack>
  );
}


