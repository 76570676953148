import React from 'react';
import { Box, Stack, Flex, VStack, Heading, Text, useDisclosure, Switch, HStack, Button, Spacer } from '@chakra-ui/react';
import PriceWrapper from '../elements/PriceWrapper';
import pricingPlans from '../content/pricingData';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const ThreeTierPricing = () => {
  const { isOpen: isMonthly, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <VStack bg="brand.500" minW="full" maxW="full">
      <VStack p={{ base:10, md:20 }}  spacing="10" bg="#F4EBFF"  textAlign="center" width="full" id="pricing-section">
        <VStack  maxW={{ base:"100%" , md:"70%" }} spacing="10">
          <Heading as="h2" fontSize="5xl" lineHeight={1.2} color="#422678">
            Friendly pricing plans
          </Heading>
          <Text size="lg" color="#4D5761">
            Choose a plan and start your 14-day free trial
          </Text>
          <Flex justify="center" align="center" >
            <Text mr={2} color="#6C727E">Monthly</Text>
            <Switch
              isChecked={!isMonthly}
              onChange={onToggle}
              colorScheme="purple"
            />
            <Text ml={2} color="#6C727E">Annually</Text>
          </Flex>
          </VStack>
          <Flex direction={['column', 'column', 'row']} align="stretch" justify="center" wrap="wrap" spacing={8}>
            {pricingPlans.map((plan, index) => (
              <PriceWrapper
                key={index}
                planName={plan.planName}
                pricePerMonth={plan.pricePerMonth}
                priceAnnualPerMonth={plan.priceAnnualPerMonth}
                isMonthly={isMonthly}
                features={plan.features}
              />
            ))}
          </Flex>
          
        </VStack>
 
          <Stack 
            alignItems={{base:"center", md:"left" }}
            direction={{ base: 'column', md: 'row' }} 
            m={{base:"10", md: "20"}}  bg="brand.500" 
            spacing="10">
            <Text fontSize="4xl" fontWeight={"bold"} color="white"  textAlign={{base:"center", md:"left" }} maxW={{base:'100%', md: '50%'}}>
              Excited to work together on your next project?
            </Text>
            <Spacer/>
            <HStack >
              <Link to="https://app.whatcampaign.com/login" >
                <Button size="sm" rounded="md" color={"white"} bg={"brand.500"}>Login</Button>
              </Link>
              <Link to="https://app.whatcampaign.com/" >
                <Button size="sm" rounded="md" color={"brand.500"} bg={"white"}>
                  <ScrollLink to={'pricing-section'} smooth={true} duration={500}   >
                    {"Get started"}
                  </ScrollLink>
                </Button>
                </Link>
            </HStack>
          </Stack>

    </VStack>
  );
};

export default ThreeTierPricing;