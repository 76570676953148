import avatar3 from '../../assets/avatars/avatar1.png';
import avatar1 from '../../assets/avatars/avatar2.png';
import avatar2 from '../../assets/avatars/avatar3.png';

const testimonialsData = [
  {
    name: "Brian Clark",
    title: 'VP of Marketing',
    avatar: avatar1,
    numberOfStars: 5,
    feedback: "As a small business owner, I found it incredibly easy to publish ads and reach my target audience effectively."
  },
  {
    name: "Mike Chen",
    title: 'VP of Marketing',
    avatar: avatar2,
    numberOfStars: 5,
    feedback: "I can't recommend this ad platform enough. It streamlined the entire process of publishing ads, allowing me to focus more on my business."
  },
  {
    name: "Jens Larsen",
    title: 'VP of Marketing',
    avatar: avatar3,
    numberOfStars: 5,
    feedback: "WhatCampaign is like a digital marketing guru sitting near me and saving my time by doing all the manual work!"
  }


];

export default testimonialsData;